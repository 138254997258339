<template>
  <div class="compensateForListContainer">
    <van-nav-bar
      title="找回赔付"
      left-arrow
      :safe-area-inset-top="true"
      @click-left="onClickLeft"
      :fixed="true"
      :border="false"
      :placeholder="true"
      v-show="is_navbar === 'true'"
    />
    <div class="compensateForListCenter" v-show="!defaultPage">
      <van-list v-model="loading" :finished="finished" @load="onLoad">
        <div
          class="compensateForList"
          v-for="(item, index) in goodList"
          :key="index"
        >
          <div class="compensateForItem">
            <div class="compensateForItemLeft">
              <img :src="item.pic" alt="" srcset="" class="headImg" />
              <div class="headImg mask">
                {{ item.claim_time_speed }}小时内赔付
              </div>
            </div>
            <div class="compensateForItemRight">
              <div class="title">
                {{ item.title }}
              </div>
              <div class="priceContainer">
                <div class="priceLeft">赔付金额：</div>
                <div class="priceRight">{{ item.claim_amount }}元</div>
              </div>
              <div class="priceDate">{{ getMoment(item.claim_at) }}</div>
            </div>
            <img
              src="../../static/images/payforIcon.png"
              alt=""
              srcset=""
              class="payforIconImg"
            />
          </div>
          <van-divider />
        </div>
      </van-list>
    </div>
    <div class="defaultPageContainer" v-show="defaultPage">
      <div class="imgContainer">
        <img
          src="../../static/images/defaultPage@2x.png"
          alt=""
          class="defaultPageImg"
        />
        <div class="defaultPageText">暂时还没有案例哦~</div>
      </div>
    </div>
  </div>
</template>

<script>
import { getMaterialList } from "@/api/security";
export default {
  name: "CompensateForList",
  data() {
    return {
      goodList: [],
      //   是否处于加载状态，加载过程中不触发load事件
      loading: false,
      finished: false,
      page: 1,
      page_size: 10,
      type: 3,
      defaultPage: false,
      is_navbar: "true",
    };
  },
  mounted() {
    if (JSON.stringify(this.$route.query) != "{}") {
      this.is_navbar = this.$route.query.is_navbar || "true";
    }
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    onLoad() {
      let config = {
        type: this.type,
        page: this.page,
        page_size: this.page_size,
      };
      this.requestGetMaterialList(config);
    },
    // 商品列表请求
    async requestGetMaterialList(config) {
      this.loading = true;
      try {
        const result = await getMaterialList(config);
        if (result.code === 20000 || result.status_code === 1000) {
          let goodList = [...this.goodList, ...result.data.data];
          let newobj = {};
          // 去重
          this.goodList = goodList.reduce((preVal, curVal) => {
            newobj[curVal.id] ? "" : (newobj[curVal.id] = preVal.push(curVal));
            return preVal;
          }, []);
          // 没有数据了
          if (result.data.data.length < this.page_size) {
            this.finished = true;
          } else {
            this.page += 1;
            this.loading = false;
          }
          if (this.page === 1 && result.data.data.length === 0) {
            this.defaultPage = true;
          } else {
            this.defaultPage = false;
          }
          // 缺省页
        } else {
          this.$toast(result.message);
        }
      } catch (error) {
        this.$toast("请求失败");
      }
    },
    getMoment(time) {
      if (this.$moment(time).format("YYYY-MM-DD") === "Invalid date") {
        return "";
      } else {
        return this.$moment(time).format("YYYY-MM-DD");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.compensateForListContainer {
  .compensateForListCenter {
    width: 100%;
    height: 100px;
    padding: 16px 20px 0px 20px;
    box-sizing: border-box;
    .compensateForList {
      .compensateForItem {
        display: flex;
        position: relative;
        .compensateForItemLeft {
          width: 82px;
          height: 82px;
          border-radius: 4px;
          .headImg {
            width: 100%;
            height: 100%;
            border-radius: 4px;
          }
          .mask {
            position: absolute;
            top: 0px;
            left: 0px;
            width: 82px;
            height: 82px;
            background: rgba(0, 0, 0, 0.6);
            border-radius: 4px;
            font-size: 11px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #ffffff;
            line-height: 82px;
            text-align: center;
          }
        }
        .compensateForItemRight {
          margin-left: 12px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .title {
            width: 241px;
            height: 40px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #444444;
            line-height: 20px;
            display: -webkit-box;
            overflow: hidden;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
          .priceContainer {
            display: flex;
            .priceLeft {
              height: 17px;
              font-size: 12px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #888888;
              line-height: 17px;
            }
            .priceRight {
              height: 17px;
              font-size: 12px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #f44444;
              line-height: 17px;
            }
          }
          .priceDate {
            height: 17px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #888888;
            line-height: 17px;
          }
        }
        .payforIconImg {
          width: 49px;
          height: 42px;
          position: absolute;
          right: 0px;
          bottom: 0px;
        }
      }
    }
  }
  .defaultPageContainer {
    width: 100%;
    height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;
    .imgContainer {
      width: 161px;
      height: 160px;
      .defaultPageImg {
        width: 100%;
        height: 100%;
      }
      .defaultPageText {
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #888888;
        line-height: 20px;
        text-align: center;
      }
    }
  }
}
::v-deep .van-nav-bar .van-icon {
  color: black;
}
</style>
